const colors = {
  primary: '#525df9',
  secondary: '#57d7ba',
  tertiary: '#3f3e56',
  dark: '#2f2f41',
  light: '#f2f2f2',
  white: '#fff'
};

export default colors;
